<template>
  <a-layout style="background: #fff">
    <Header />
    <div class="header-title">
      <span>字符串转义和去除转义</span>
    </div>
    <div class="container">
      <a-form layout="inline" style="text-align: center">
        <a-form-item>
          <a-textarea
            style="width: 400px"
            size="large"
            v-model="text"
            allow-clear
            placeholder="未转义"
            rows="20"
          />
        </a-form-item>
        <a-form-item>
         <div>
          <a-button
            @click="handleQuote"
            type="primary"
          >
            转义 -&gt; </a-button>
         </div>

           <div>
            <a-button
            @click="handleUnquote"
            type="primary"
          >
          &lt;- 反转</a-button>
           </div>
        </a-form-item>
        <a-form-item>
          <a-textarea
            style="width: 400px"
            size="large"
            v-model="result_text"
            allow-clear
            placeholder="已转义"
            rows="20"
          />
        </a-form-item>
      </a-form>
    </div>
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";


export default {
  name: "Quote",
  props: {
    query: String,
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      text: "",
      result_text: "",
    };
  },
  mounted () {
    if(this.query) {
      this.text = this.query
    }
    document.title = "不便利 - 转义大师"
  },
  watch: {},
  computed: {},
  methods: {
    handleQuote: function () {
      this.loading = true;
      this.$http
        .post("/api/tools/quote",{}, {
          content:  this.text,
        })
        .catch((e) => {
          const errmsg = e.response.data
            ? JSON.stringify(e.response.data.error)
            : e.toString();
          this.loading = false;
          this.$error({
            title: "网络错误",
            content: errmsg,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.result_text = resp.data.data.content;
        });
    },
    handleUnquote: function () {
      this.loading = true;
      this.$http
        .post("/api/tools/unquote",{}, {
          content:  this.result_text,
        })
        .catch((e) => {
          const errmsg = e.response.data
            ? JSON.stringify(e.response.data.error)
            : e.toString();
          this.loading = false;
          this.$error({
            title: "网络错误",
            content: errmsg,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.text = resp.data.data.content;
        });
    },
  },
};
</script>

<style scoped lang="less">



</style>
