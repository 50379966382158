import axios from 'axios'
import { storage } from '@/utils/index.js'

const setup = function (instance) {
  instance.interceptors.response.use((response) => {
    return response
  }, (e) => {
    console.log(e)
    e.response.error = {
      title: `网络异常 ${e.response.status}`,
      content: `${e.response.statusText}: ` + JSON.stringify(e.response.data),
    }
    if (e.response.status == 401) {
      location.href = "/#/"
    }
    return Promise.reject(e)
  })

}

export default {
  get(url, params) {
    const instance = axios.create();
    setup(instance)
    return instance.get(url, {
      headers: {
      },
      params,
    })
  },
  post(url, params, data) {
    const instance = axios.create();
    setup(instance)
    return instance({
      method: "POST",
      url,
      params,
      data: data,
      headers:
      {
        'Content-Type': 'application/json'
      }
    })
  }
}