<template>
  <div class="footer">
    <div class="footer-statement" >
      <p>微博: <a href="https://weibo.com/u/2231809290" target="__blank">果仁菠菜 JIA</a></p>
      <p >
        如果以上内容存在第三方版权内容，则由网络爬虫自动抓取，以非人工方式自动生成。Bubianli.com
        不储存、复制、传播任何第三方版权文件。
      </p>
      <p >copyright © {{ copyyear }} bubianli.com</p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Footer",
  props: {
    styles: Object,
    size: String,
    focus: Number,
  },
  debounceFunc: null,
  inputPreText: "",
  data() {
    return {
      copyyear: (new Date()).getFullYear(),
      text: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang='less'>
.footer {
  color: #aaa;
  font-size: 12px;
}
.footer-content {
  width: 1000px;
  margin: 0 auto;
}
.footer-statement {
  text-align: center;
  margin: 100px auto 20px;
}
.footer-copyright {
  text-align: center;
  margin: 50px auto 20px;
}


a {
  color: #666;
}
</style>
  