<template>
  <a-layout style="background: #fff">
    <Header />
    <div class="header-title">
      <span>知乎「三巨头」处理 （中英文中间有空格、双引号改为「曲尺形」的引号）</span>
    </div>
    <div class="container">
      <a-form layout="inline" style="text-align: center">
        <a-form-item>
          <a-textarea
            style="width: 400px"
            size="large"
            v-model="text"
            allow-clear
            placeholder="待处理文本"
            rows="20"
          />
        </a-form-item>
       
        <a-form-item>
          <a-textarea
            style="width: 400px"
            size="large"
            v-model="result_text"
            allow-clear
            placeholder=""
            rows="20"
          />
        </a-form-item>
      </a-form>
       <div style="text-align: center;">
         <a-button
            @click="handleRun"
            type="primary"
          >
            三巨头化</a-button
          >
       </div>
    </div>
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";

function isChinese(temp)  
{  
  let re=/[^\u4e00-\u9fa5]/;  
  if(re.test(temp)) return false;  
  return true;  
}  

function isDiffChinese(tmp){
  let patternEn = new RegExp("[A-Za-z]+");
  if (patternEn.test(tmp)) {
    return true
  }
  let patternNm = new RegExp("[0-9]+");
  if (patternNm.test(tmp)) {
    return true
  }
  return false
}
function replaceQuote(tmp){
  tmp = tmp.replaceAll("“", "「")
  tmp = tmp.replaceAll("”", "」")
  tmp = tmp.replaceAll("‘", "『")
  tmp = tmp.replaceAll("’", "』")
  return tmp
}



export default {
  name: "ThreeBig",
  props: {
    query: String,
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      text: "",
      result_text: "",
    };
  },
  mounted () {
    if(this.query) {
      this.text = this.query
    }
    document.title = "不便利 - 知乎三巨头"
  },
  watch: {},
  computed: {},
  methods: {
    handleRun: function () {
      
      let text = this.text
      let textAfter = text[0]
      for (let pre = 1,sub = 0; pre < text.length; pre ++) {
        console.log(text[pre] ,text[sub])
        console.log(isChinese(text[pre]) ,isDiffChinese(text[sub]))

        if (isChinese(text[pre]) && isDiffChinese(text[sub])) {
          textAfter += ' '
        } else if (isChinese(text[sub]) && isDiffChinese(text[pre])) {
          textAfter += ' '
        }
        textAfter += text[pre]
        sub = pre
        
      }
      this.result_text = replaceQuote(textAfter)
    },
  },
};
</script>

<style scoped lang="less">



</style>
