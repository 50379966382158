<template>

<header class="header">
  <div style="height: 1px;visibility: hidden;">
    <img   src="../assets/eva-bubianli.jpg" />
  </div>
  <div>
    <a href='/' ><span>B U B I A N L I 不 便 利</span></a>
  </div>
  <div style="font-size: 12px; color: #999;">Inconvenient</div>
</header>
</template>
  
<script>

export default {
  name: 'Header',
  props: {
    
  },
  data(){
    return {
      
    }
  },
  components: {},
  loopCheckOrderStatusTimer: null,
  mounted(){},
  watch:{
    
  },
  computed:{
    
  },
  unmount(){
  },
  methods: {
    
  
  }
}
</script>
  
<style scoped lang='less'>
.header a {
  color: #333;
}
 .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #eEE;
}

</style>
