<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { storage } from "@/utils";

export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    document.title= "不便利店"
  },
};
</script>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?7b6fd5e1848c4cb6a32c487dadeb194c";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>

<style>
#app {
  background: #FFF;
}
#app .ant-layout {
  background: #FFF;
}
a {
  color: #666;
}
.outstand {
  color: #ed1b25 !important;
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.container {
  width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .container {
    margin: 0 auto;
    width: auto;
    padding-top: 20px;
  }
}
</style>