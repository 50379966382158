<template>
  <a-layout :class="{'is-simple': isSimple}" style="background: #fff">
    <Header v-if="!this.isSimple" />
    <div class="header-title">
      <span>排期日历</span>
    </div>
    <div class="top-badge" >
      <a target="_blank" href="https://bubianli.com/calendar">排期日历</a>
    </div>
    <div class="calendar-main" >
      <div class="size-func" >
        <a-radio-group v-model="isLarge">
          <a-radio-button :value="true">大</a-radio-button>
          <a-radio-button :value="false">小</a-radio-button>
        </a-radio-group>
      </div>
      <div class="demos" >
        <ul>
          <li class="cal-item" >Kickoff 🙇</li>
          <li class="cal-item" >RFC 📖</li>
          <li class="cal-item" >开发 🔨</li>
          <li class="cal-item" >联调 🤼‍♂️</li>
          <li class="cal-item" >测试 🚥</li>
          <li class="cal-item" >上线 🚀</li>
          <li class="cal-item cal-item-red" >休假 🌴</li>
          <li class="cal-item cal-item-red" >团建 🤼</li>
        </ul>
      </div>
      
      <div class="cal-week-title">
        <div class="call-week-row-prefix" >
            星期
        </div>
        <div class="cal-item"  :class="{'large': isLarge}" v-for="v of weeks" :key="v" >
          {{v}}
        </div>
        <div class="call-week-row-subfix" >
            
        </div>
      </div>
   
      <div class="cal-week-wrap" v-for="(month, monthIndex) in days" :key="monthIndex">
        <div class="cal-week-row" v-for="(week, weekIndex) in month.weeks" :key="weekIndex">
          <div class="call-week-row-prefix" >
            <div>
              {{(month.month % 12  == 0 || monthIndex == 0 ) && weekIndex == 0 ? month.year + "年": ""}} 
            </div>
            {{month.month % 12 + 1}} 月
          </div>
          <div class="cal-item" 
          :class='{
            "cal-item-red" : day.color == "red",
            "cal-item-yellow" : day.color == "yellow",
            "cal-item-grey" : day.color == "grey",
            "cal-item-blue" : day.color == "blue",
            "cal-item-green" : day.color == "green",
            "is-today": day.is_today,
            "large": isLarge,
            }' 
          v-for="(day, dayIndex) of week" 
          :key="dayIndex" 
          >
            <div class="cal-inner" v-if="day.datetime"  onselectstart="return false" >
              <div v-if="day.holiday" class="cal-holiday cal-badge"   >{{day.holiday}}</div>
              <div v-if="day.workday" class="cal-workday cal-badge"  >{{day.workday}}{{ day.subtext ?  `(` + day.subtext + `)` : ''  }}</div>
              <div class="cal-date" @click="changeColor(monthIndex, weekIndex, dayIndex)">{{day.datetime.getDate()}}</div>
              <div class="cal-text"  >
                <textarea class="inline-input" @change="onChangeText(day.text, monthIndex, weekIndex, dayIndex)" v-model="day.text" ></textarea>
              </div>
            </div>
        </div>
        <div class="call-week-row-subfix" >
          <div v-if="monthIndex == 0 && weekIndex == 0" class="addon-btn-wrap" >
            <a-button  @click="addPrevMonth">+</a-button>
          </div>
          <div v-if="monthIndex == days.length - 1 && weekIndex == month.weeks.length - 1" class="addon-btn-wrap" >
            <a-button  @click="addNextMonth">+</a-button>
          </div>
        </div>
      </div>
        
      </div>
    </div>
    <div class="export-btn-wrap" >
      <a-button  @click="exportDays">导出 HTML 内嵌代码</a-button>
      <input v-if="this.exportEmbed" v-model="exportEmbed" >
      <p class="embed-tip" v-if="this.exportEmbed" >在 Confluence Wiki 中使用方法：插入宏「HTML」，然后将上面代码复制到 HTML 内容里即可。</p>
    </div>
    <Footer v-if="!this.isSimple" />
    <FooterMini v-else />
  </a-layout>
</template>

<script>
import { message } from 'ant-design-vue';

import Footer from "../components/Footer";
import Header from "../components/Header";
import FooterMini from "../components/FooterMini"

const Month31Days = [1,3,5,7,8,10,12]
const Holidays =  {
  2024: {
      1: {
        "元旦": [1],
      },
      2: {
        "除夕": [9],
        "春节": [10,11,12,13,14,15,16,17]
      },
      4: {
        "清明": [4,5,6]
      },
      5: {
        "劳动节": [1,2,3,4,5]
      },
      6: {
        "端午节": [8,9,10]
      },
      9: {
        "中秋节": [15,16,17]
      },
      10: {
        "国庆": [1,2,3,4,5,6,7]
      }
    },
  2022: {
    9: {
      "中秋节": [10,11,12]
    },
    10: {
      "国庆": [1,2,3,4,5,6,7]
    }
  },
  2023: {
    1: {
      "除夕": [21],
      "春节": [22]
    },
    4: {
      "清明": [5],
      "劳动节": [29, 30],
    },
    5: {
      "劳动节": [1,2, 3],
    },
    6: {
      "端午节": [22,23, 24],
    },
    9: {
      "中秋节": [29,30],
    },
    10: {
      "国庆节": [1,2,3,4,5,6],
    },
    12: {
      "元旦": [30,31],
    },
  },
}
const Workdays = {
  2024: {
    2: {
      "春节": [4, 18]
    },
    4: {
      "清明": [7],
      "劳动节": [28]
    },
    5: {
      "劳动节": [11]
    },
    9: {
        "中秋节": [14],
        "国庆": [29]
    },
    10: {
        "国庆": [12]
    },

  },
  2022: {
    10: {
      "国庆": [8,9]
    }
  },
  2023: {
    4: {
      "劳动节": [23]
    },
    5: {
      "劳动节": [6],
    },
    6: {
      "端午节": [25],
    },
    10: {
      "国庆节": [7, 8],
    },
  }
}
export default {
  name: "Calendar",
  props: {
    snap: String,
  },
  components: {
    Footer,
    Header,
    FooterMini,
  },
  data() {
    let days = this.initSnapDays()
    return {
      isLarge: false,
      weeks: ["一", "二", "三", "四", "五", "六", "日"],
      days: days,
      thatDate: new Date,
      snapDays: {},
      exportEmbed: '',
    };
  },
  mounted () {
    document.title="不便利-排期日历"

  },
  watch: {},
  computed: {
    isSimple: function(){
      if (top.location != location) {
        return true
      }
      return false
    }
   },
  methods: {
    addNextMonth(){
      let thatDay =  this.days[this.days.length - 1]
      let maxMonth = thatDay.month
      let thatDate = new Date
      thatDate.setFullYear(thatDay.year, thatDay.month, 1)
      if (maxMonth == 11) {
        thatDate.setFullYear(thatDate.getFullYear() + 1)
        maxMonth = 0
      } else {
        maxMonth ++
      }
      this.days.push(this.monthDates(thatDate, maxMonth))
      this.$forceUpdate()
    },
    addPrevMonth(){
      let minMonth = this.days[0].month
      let thatDate = new Date
      thatDate.setFullYear(this.days[0].year, this.days[0].month, 1)
      if (minMonth == 0) {
        thatDate.setFullYear(thatDate.getFullYear() - 1)
        minMonth = 11
      } else {
        minMonth --
      }
      this.days.unshift(this.monthDates(thatDate, minMonth))
      this.$forceUpdate()
    },
    nextNMonthDate(currentTime, monthCount){
      let currentMonth = currentTime.getMonth()
      let addYear = Math.floor((currentMonth + monthCount) / 12)
      let lastMonth = (currentMonth + monthCount) % 12
      let retDate = new Date
      retDate.setFullYear(currentTime.getFullYear() + addYear, lastMonth, 1)
      return retDate
    },
    initSnapDays(){
      let snap = null
      let days = []
      let currentTime = new Date
      // 如果当前时间超过两周，自动显式下个月的日历
      let defaultToTime = new Date(currentTime.getTime() + 7 * 2 * 86400000)
      if(this.snap) {
        try {
          snap = JSON.parse(atob(this.snap))
        } catch (e){
          console.warn(e)
          this.snapDays = null
          return this.initDays(currentTime, defaultToTime)
        }
      }

      if(snap) {
        this.thatDate =  new Date(snap.that_date)
        let snapDays = snap.days
        // 计算需要展示几个月
        let minDatetime,maxDatetime = new Date
        for(let y in snapDays) {
          for(let m in snapDays[y]) {
            if (!minDatetime) {
              minDatetime = new Date
              minDatetime.setFullYear(y, m, 1)
            }
            maxDatetime.setFullYear(y, m, 1)
          }
        }
        if (!minDatetime) {
          minDatetime = currentTime
        }
        if (!maxDatetime) {
          maxDatetime = defaultToTime
        }
        days = this.initDays(minDatetime, maxDatetime)        
        for(let month of days) {
          for(let week of month.weeks) {
            for(let day of week) {
              if(!day.datetime) continue
              if(snapDays[day.datetime.getFullYear()]
              && snapDays[day.datetime.getFullYear()][day.datetime.getMonth()]
              && snapDays[day.datetime.getFullYear()][day.datetime.getMonth()][day.datetime.getDate()]) {
                let tmp = snapDays[day.datetime.getFullYear()][day.datetime.getMonth()][day.datetime.getDate()]
                day.holiday = decodeURIComponent(tmp.holiday)
                day.workday = decodeURIComponent(tmp.workday)
                day.text = decodeURIComponent(tmp.text)
                day.color = tmp.color
                day.is_valid = true
                
              }
            }
          }
        }
      } else {
        days = this.initDays(currentTime, defaultToTime)
      }
      return days
    },
    initDays(currentTime, toTime){
      let days = []
      let month = currentTime.getMonth() 

      let toMonthCount = (toTime.getFullYear() - currentTime.getFullYear()) * 12 - currentTime.getMonth() + toTime.getMonth() + 1
      for(let i = 0 ; i < toMonthCount; i++) {
        days.push(this.monthDates(currentTime, month + i))
      }
      return days
    },
    exportDays(){
      let saveDays = {} // year -> month -> day
      for(let month of this.days) {
        for(let days of month.weeks) {
          for(let day of days) {
            if (day.is_valid) {
              if (!saveDays[day.datetime.getFullYear()]) {
                saveDays[day.datetime.getFullYear()] = {}
              }
              if (!saveDays[day.datetime.getFullYear()][day.datetime.getMonth()]) {
                saveDays[day.datetime.getFullYear()][day.datetime.getMonth()] = {}
              }
              saveDays[day.datetime.getFullYear()][day.datetime.getMonth()][day.datetime.getDate()]= {
                color: day.color,
                timestamp: day.datetime.getTime(),
                holiday: encodeURIComponent(day.holiday),
                workday: encodeURIComponent(day.workday),
                text: encodeURIComponent(day.text),
              }
            }
          }
        }
      }
      let snap = {
        that_date: this.thatDate,
        days: saveDays,
      }
      let base64Snap = btoa(JSON.stringify(snap))
      this.exportEmbed = `<embed style="border: 1px solid #EEE;" width=700 height=600 src="https://bubianli.com/calendar?snap=${base64Snap}" />`
      if (navigator.clipboard) {
        message.success("已复制到剪切板")
          navigator.clipboard.writeText(this.exportEmbed);
      }
    },
    onChangeText: function(v, index,w, d){
      this.days[index].weeks[w][d].is_valid= v == "" ? false : true
    },
    changeColor: function(index, weekIndex, dayIndex) {
      let origin = this.days[index].weeks[weekIndex][dayIndex]
      let defaultColors = ['red', 'yellow', 'green', 'blue', 'grey']
      let nextColor = defaultColors.indexOf(origin.color) + 1
      if(nextColor >= defaultColors.length) {
        nextColor = 0
      }
      this.days[index].weeks[weekIndex][dayIndex] = {
        ...origin,
        color: defaultColors[nextColor]
      }
      this.$forceUpdate()
    },
    worktime: function(year, month,date) {
      if(!Workdays[year]) {
        return ""
      }
      let readableMonth = month + 1
      if(Workdays[year][readableMonth]) {
        for(let n in Workdays[year][readableMonth]) {
          if(Workdays[year][readableMonth][n].indexOf(date) == -1) {
            continue
          }
          return n
        }
      }
      return ""
    },
    holiday: function(year, month,date){
      if(!Holidays[year]) {
        return ""
      }
      let readableMonth = month + 1
      if(Holidays[year][readableMonth]) {
        for(let n in Holidays[year][readableMonth]) {
          if(Holidays[year][readableMonth][n].indexOf(date) == -1) {
            continue
          }
          return n
        }
      }
      return ""
    },
    isToday: function(currentTime, thatDay){
      return (
        currentTime.getFullYear() == thatDay.getFullYear() &&
        currentTime.getMonth() == thatDay.getMonth() &&
        currentTime.getDate() == thatDay.getDate()
      )
    },
    monthDates: function(thatDate, originMonth){
      let month = originMonth % 12
      if (month < 0) {
        month = 12 + month
      }
      let currentTime = new Date
      currentTime.setFullYear(thatDate.getFullYear() + Math.floor(originMonth / 12), month, 1)
      
      // 计算月的天数
      let dc = 28
      if (month + 1 == 2) {
        if (currentTime.getFullYear() % 4 == 0 && currentTime.getFullYear() % 100 !=0 
          || currentTime.getFullYear() % 400 == 0) {
          dc = 29
        } 
      } else {
        dc = Month31Days.indexOf(month + 1) > -1 ? 31 : 30
      }
      
      // 每月第一天
      let weekFirstDate = new Date
      weekFirstDate.setFullYear(currentTime.getFullYear(), month, 1)
      let weekFirstDay = weekFirstDate.getDay()
      if (weekFirstDay == 0) {
        weekFirstDay = 7
      }

      let weeksDays = [[]]
      let weekIndex = 0
      // 缩进日期
      for (let i = 1; i< weekFirstDay; i++) {
        weeksDays[weekIndex].push({
          text: "", // 占位
        })
      }
      // 正经日期
      for (let d = 1; d <= dc; d++) {
        // 初始化每周日期
        if(weeksDays[weekIndex].length == 7) {
          weekIndex ++
          weeksDays[weekIndex] = []
        }

        let thatDay = new Date
        thatDay.setFullYear(weekFirstDate.getFullYear(), month, d)
        
        let hld = this.holiday(thatDay.getFullYear(), month, d)
        let wkd = this.worktime(thatDay.getFullYear(), month,d)
        let subtext = ""
        let color = ""
        if(hld) {
          subtext = "休"
          color = "red"
        } else if(wkd) {
          subtext = "班"
          color = 'yellow'
        } else {
          if(thatDay.getDay() == 0 || thatDay.getDay() == 6) {
            color = 'red'
          }
        }
        
        
        weeksDays[weekIndex].push({
          datetime: thatDay,
          holiday: hld,
          workday: wkd,
          subtext: subtext,
          text: subtext,
          color: color,
          is_valid: false,
          is_today: this.isToday(new Date, thatDay),
        })
      }

      // 补齐尾号
      for (let i = weeksDays[weekIndex].length; i< 7; i++) {
        weeksDays[weekIndex].push({
          text: "" // 占位
        })
      }
      return {
        year: weekFirstDate.getFullYear(),
        month: month,
        weeks: weeksDays,
      }
    }
   
  },
};
</script>

<style scoped lang="less">

.cal-week-title {
  display: flex;
  justify-content: center;
  align-items: center;
  .cal-item {
    height: 30px;
    line-height: 30px;
    font-weight: bold;
  }
  .call-week-row-subfix {
    height: inherit;
  }
}


.cal-week-title .cal-item:nth-last-child(2), .cal-week-title .cal-item:nth-last-child(3) {
  color: #F73131;
  background:#fff5f5;
}

.cal-item {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 1px;
  text-align: center;
  border-radius: 4px;
  color: #0455be;
  background: #f8fbff;
}

.cal-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.cal-item.large {
  width: 120px;
  height: 150px;
}

.cal-week-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-week-row-prefix {
  width: 70px;
  font-size: 18px;
  color: #636363;
  text-align: right;
  padding-right: 4px;
  // font-weight: bold;
}

.call-week-row-subfix {
  position: relative;
  padding-left: 4px;
  height: 80px;
}

.cal-badge {
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  border-radius: 0 0 10px 0;
  font-size: 12px;
  padding: 2px 4px;
}


.cal-item-grey {
  color: #444 !important;
  background:#ededed !important;
  .cal-badge {
    color: #ededed !important;
    background:#444 !important;
  }
  &.is-today {
    border-color: #444 !important;
  }
}


.cal-item-red {
  color: #F73131 !important;
  background:#fff5f5 !important;
  .cal-badge {
    color: #ffffff !important;
    background:#bc3b3b !important;
  }
  &.is-today {
    border-color: #bc3b3b !important;
  }
}



.cal-item-yellow {
  color: #474827 !important;
  background:#ffffe9 !important;
  .cal-badge {
    color: #ffffff !important;
    background:#6e6e3c !important;
  }

  &.is-today {
    border-color: #6e6e3c !important;
  }
}


.cal-item-blue {
  color: #0455be !important;
  background: #f8fbff !important;
  .cal-badge {
    color: #f8fbff  !important;
    background: #0455be !important;
  }
  &.is-today {
    border-color: #0455be !important;
  }
}

.cal-item-green {
  color: #057a4f !important;
  background: #f8fffc !important;
  .cal-badge {
    color: #f8fbff  !important;
    background: #057a4f !important;
  }
  &.is-today {
    border-color: #057a4f !important;
  }
}


.cal-date {
  font-size: 18px;
  font-weight: bold;
  margin: 22px 0 2px;
  cursor: pointer;
}

.cal-text {
  cursor:text;
  flex: 1;
}

.demos ul {
  display: flex;
  justify-content: center;
  padding: 0;
  .cal-item {
    height: 30px;
    line-height: 30px;
  }

}
.export-btn-wrap {
  text-align: center;
  margin: 20px auto;
  input {
    width: 400px;
    border: 1px solid #EEE;
    display: block;
    margin: 10px auto;
    height:30px;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

.inline-input {
  border:0;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  text-align: center;

  // text-align: inherit;
  // display: inline;
  // color: inherit;
}

.calendar-main {
  padding: 40px 0;
}

.top-badge {
  background: #DDD;
  color: #FFF;
  border-radius: 0 0 0 20px;
  position: absolute;
  top: 0;
  right: 0;
  width:80px;
  font-size: 12px;
  text-align: center;
  a {
    color: #FFF;
  }
}


.is-today {
  border: 1px solid #EEE;
  box-shadow: 1px 1px 2px #9E9E9E;
}

.addon-btn-wrap {
  text-align: center;
  top: 20px;
  left: 20px;
  position: absolute;
}

.is-simple {
  .demos {
    display: none;
  }
  .size-func {
    display: none;
  }
  .addon-btn-wrap  {
    display: none
  }
  .header-title {
    display: none;
  }
  .export-btn-wrap {
    display: none;
  }
}

.embed-tip {
  background: #0049B0;
  color: #FFF;
}

.size-func {
  text-align: center;;
  padding: 20px;
}
</style>