<template>
  <div class="footer">
    <div class="footer-statement" >
      <p >copyright © 2022 bubianli.com</p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Footer",
  debounceFunc: null,
  inputPreText: "",
  data() {
    return {
      text: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang='less'>
.footer {
  color: #aaa;
  font-size: 12px;
}
.footer-content {
  width: 1000px;
  margin: 0 auto;
}
.footer-statement {
  text-align: center;
  margin: 20px auto 20px;
}
.footer-copyright {
  text-align: center;
  margin: 50px auto 20px;
}


a {
  color: #666;
}
</style>
  