<template>
  <a-layout style="background: #fff">
    <Header />
    <div class="header-title">
      <span>文 字 去 重</span>
    </div>
    <div class="container">
      <a-form
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item label="换行字符">
          <a-input placeholder="可使用 \n \t， 默认 ,\n" v-model="space" />
        </a-form-item>
        <a-form-item label="类型">
          <a-radio-group v-model="content_type">
            <a-radio-button value="number"> 数字 </a-radio-button>
            <a-radio-button value="string"> 字符串 </a-radio-button>
          </a-radio-group>
          <div style="font-size: 12px; color: #777;">注意：浏览最大安全数字为 9007199254740991，超过此数值数字会丢失精度</div>
        </a-form-item>
      </a-form>
      <a-progress :percent="progress_percent" />
      <a-form layout="inline" style="text-align: center">
        <a-form-item>
          <a-textarea
            style="width: 400px"
            addonBefore="待去重数字"
            size="large"
            v-model="raw_text"
            allow-clear
            placeholder="待去重数字"
            rows="20"
          />
        </a-form-item>
       
        <a-form-item>
          <a-textarea
            style="width: 400px"
            addonBefore="去重结果"
            size="large"
            v-model="result_text"
            allow-clear
            placeholder="去重结果"
            rows="20"
          />
        </a-form-item>
      </a-form>
      <div style="text-align: center;">
        <a-button
            :loading="loading"
            @click="handleDeDuplicate"
            type="primary"
          >
            去重</a-button
          >
      </div>
       
    </div>
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  name: "Bilibili",
  props: {
    query: String,
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      progress_percent: 0,
      loading: false,
      space: ",\n",
      raw_text: "",
      result_text: "",
      content_type: "number",
    };
  },
  mounted: function () {
    document.title = "不便利 - 数据去重"
  },
  watch: {},
  computed: {},
  methods: {
    handleDeDuplicate: function () {
      this.progress_percent = 1;
      let raw_array = this.raw_text
        .replace(" ", "\n")
        .replace(".", "\n")
        .replace(",", "\n")
        .replace("\r", "\n")
        .replace("\t", "\n")
        .split("\n");
      let raw_map = {};
      this.progress_percent = 20;
      let total_len = 0;
      for (let i of raw_array) {
        let some = i;
        if (this.content_type == "number") {
          some = +i;
          if (isNaN(some)) {
            continue;
          }
        }

        raw_map[some] = true;
        total_len++;
      }

      this.progress_percent = 40;
      let result = [];
      for (let i in raw_map) {
        result.push(i);
        this.progress_percent += 50 / total_len;
      }
      this.progress_percent = 90;
      this.space = this.space.replace("\\n", "\n").replace("\\t", "\\t");
      this.result_text = result.join(this.space);
      this.progress_percent = 100;
    },
  },
};
</script>

<style scoped lang="less">

</style>
