<template>
  <a-layout style="background: #fff">
    <Header />
    <div class="header-title">
      <span>富途牛牛（港股）手续费速算</span>
    </div>
    <div style="text-align: center;">
      <div style="font-size: 20px; font-weight: bold;">
        <p>总金额： {{total_amount}} </p>
        <p>总费用：{{total_fee}} </p>
        <p>剩余：{{total_amount - total_fee}}</p>
        <p>占比 -{{(total_fee / total_amount  * 100).toFixed(2)}}%</p>
      </div>
      <div>
        <a href="https://rich01.com/futu-inc-how-much-is-the-fee/" >收费标准</a>
      </div>
    </div>
    <div class="container">
      <a-form >
        <a-form-item label="单价">
          <a-input v-model="price"
          placeholder="单价"
          >
          <template #suffix>
            $
          </template>
          </a-input>
        </a-form-item>
        <a-form-item label="股数">
          <a-input v-model="volumn"
          placeholder="股数"
          >
          <template #suffix>
            股
          </template>
          </a-input>
        </a-form-item>
        <p style="color: gray; font-size: 12px;">（交易金额 优先于 单价 x 股数 计算）</p>
        <a-form-item label="交易金额">
          <a-input v-model="amount"
          placeholder="交易金额"
          >
          <template #suffix>
            $
          </template>
          </a-input>
        </a-form-item>
       
        <a-form-item label="佣金 Commission">
          <a-input v-model="commission"
          placeholder="单笔佣金"
          >
          <template #suffix>
            % min 3$
          </template>
          </a-input>
        </a-form-item>
        <a-form-item label="交收费 Clearing Fee">
          <a-input v-model="clearing_fee"
          placeholder="交收费"
          >
          <template #suffix>
            %  [2$:100$]
          </template>
          </a-input>
        </a-form-item>
        <a-form-item label="平台使用费 Platform Usage Fee">
          <a-input v-model="platform_fee"
          placeholder="交收费"
          >
          <template #suffix>
            $
          </template>
          </a-input>
        </a-form-item>
        <a-form-item label="印花税 Stamp">
          <a-input v-model="stamp"
          placeholder="印花税"
          >
          <template #suffix>
            %
          </template>
          </a-input>
        </a-form-item>
        <a-form-item label="交易征费 Transaction Levy">
          <a-input v-model="transaction_levy"
          placeholder="交易征费"
          >
          <template #suffix>
            % min 0.01$
          </template>
          </a-input>
        </a-form-item>
         <a-form-item label="交易费 Trading Fee">
          <a-input v-model="trading_fee"
          placeholder="交易费"
          >
          <template #suffix>
            % min 0.01$
          </template>
          </a-input>
        </a-form-item>
      </a-form>
      <div style="text-align: center;">
        <div style="font-size: 20px; font-weight: bold;">
        {{total_fee}}
        </div>
        <div>
          <a href="https://rich01.com/futu-inc-how-much-is-the-fee/" >收费标准</a>
        </div>
      </div>
    </div>
    
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";


export default {
  name: "FutuFee",
  props: {},
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      price: 0,
      volumn: 100,
      amount: 0,
      commission: 0.03,
      stamp: 0.13,
      transaction_levy: 0.0027,
      trading_fee: 0.005,
      clearing_fee: 0.002,
      platform_fee: 15,
      system_usage_fee: 0.5,
    };
  },
  mounted () {
    document.title="不便利-富途牛牛（港股）手续费速算"
  },
  watch: {},
  computed: {
    total_amount: function(){
      let amount = this.amount
      if (amount == 0 && this.price > 0 && this.volumn > 0 ) {
        amount = this.price * this.volumn
      }
      return amount
    },
    total_fee: function() {
      let amount = this.amount
      if (amount == 0 && this.price > 0 && this.volumn > 0 ) {
        amount = this.price * this.volumn
      }
      let commission = amount * this.commission / 100
      commission = commission > 3 ? commission : 3
      let stamp = amount * this.stamp / 100
      stamp = Math.ceil(stamp)
      let clearingFee = amount * this.clearing_fee / 100
      clearingFee = clearingFee > 2 ? clearingFee : 2
      clearingFee = clearingFee > 100 ? 100 : clearingFee
      let transactionLevy = amount * this.transaction_levy / 100
      transactionLevy = transactionLevy > 0.01 ? transactionLevy : 0.01
      let tradingFee = amount * this.trading_fee / 100
      tradingFee = tradingFee > 0.01 ? tradingFee : 0.01
      let systemUsageFee = 0.5
      let result = commission + systemUsageFee + stamp + clearingFee + transactionLevy + tradingFee + this.platform_fee
      return result.toFixed(2)
    }
  },
  methods: {
    
   
  },
};
</script>

<style scoped lang="less">



</style>
