
import Bilibili from './views/Bilibili.vue'
import Dashboard from './views/Dashboard.vue'
import ArrayDuplicate from './views/ArrayDuplicate.vue'
import ThreeBig from './views/ThreeBig.vue'
import FutuFee from './views/FutuFee.vue'
import Calendar from './views/Calendar.vue'
import Quote from './views/Quote.vue'
export default [
    {
        path: '/',
        alias: "/",
        component: Dashboard,
        name: "Dashboard",
        props: (route) => ({
            query: route.query.w,
        }),
    },
    {
        path: '/home/quote',
        component: Quote,
        name: "quote",
    },
    {
        path: '/bilibili',
        alias: "/bilibili",
        component: Bilibili,
        name: "Bilibili",
        props: (route) => ({
            query: route.query.w,
        }),
    },
    {
        path: '/array-duplicate',
        alias: "/array_duplicate",
        component: ArrayDuplicate,
        name: "ArrayDuplicate",
        props: (route) => ({
            query: route.query.w,
        }),
    },
    {
        path: '/three-big',
        alias: "/three_big",
        component: ThreeBig,
        name: "ThreeBig",
        props: route => ({
            query: route.query.w,
        }),
    }, {
        path: '/futu-fee',
        alias: '/futu_fee',
        component: FutuFee,
        name: "FutuFee"
    }, {
        path: '/calendar',
        component: Calendar,
        name: "Calendar",
        props: (route) => ({
            snap: route.query.snap,
        }),
    }

]