<template>
  <a-layout style="background: #fff">
     <Header />
    <div class="header-title">
      <img class="banner" src="../assets/bilibili_banner.png" />
      <span>视频下载中心</span>
    </div>
    <div class="container">
      <video
        style="width: 100%"
        autoplay
        loop
        src="http://static.bubianli.com/web/static/bilibili_banner_animate.mp4"
      />
      <a-form layout="inline" style="text-align: center">
        <a-form-item>
          <a-input
            addon-before="https://www.bilibili.com/video/"
            v-model="token"
            placeholder="{TOKEN}"
          />
        </a-form-item>
        <a-form-item>
          <a-button :loading="loading" @click="handleDownload" type="primary">
            下载视频</a-button
          >
        </a-form-item>
      </a-form>

      <div class="result-wrapper" v-if="video_info.video_url != ''">
        <div class="video-title">
          <span>标题: </span>
          <a
            target="_blank"
            :href="'https://www.bilibili.com/video/' + token"
            >{{ video_info.title }}</a
          >
        </div>
        <div class="video-cover-url">
          <span>封面: </span>
          <a target="_blank" :href="video_info.cover">{{ video_info.cover }}</a>
        </div>
        <div class="video-video-url">
          <span>视频: </span>
          <a target="_blank" :href="video_info.cdn_url">{{
            video_info.cdn_url
          }}</a>
        </div>
        <div class="video-video-url">
          <span>视频备份地址: </span>
          <a target="_blank" :href="video_info.video_url">{{
            video_info.video_url
          }}</a>
        </div>
      </div>
    </div>
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  name: "Bilibili",
  props: {
    query: String,
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      loading: false,
      token: "",
      video_info: {
        title: "",
        video_url: "",
        cdn_url: "",
        cover_url: "",
      },
    };
  },
  mounted: function () {
    document.title = "不便利 - Bilibili 视频下载"
  },
  watch: {},
  computed: {},
  methods: {
    handleDownload: function (word, pageNo) {
      if (!this.token) {
        return;
      }
      if (this.searching) return;
      this.loading = true;
      this.$http
        .post("/api/v1/bilibili/spider", {
          token: this.token,
        })
        .catch((e) => {
          const errmsg = e.response.data
            ? JSON.stringify(e.response.data.error)
            : e.toString();
          this.loading = false;
          this.token = "";
          this.$error({
            title: "网络错误",
            content: errmsg,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.token = "";
          if (!resp) {
            return;
          }
          const data = resp.data.data;
          this.video_info = data;
        });
    },
  },
};
</script>

<style scoped lang="less">

.banner {
  width: 100px;
}

.url-wrapper {
  font-size: 20px;
}

.btn-wrapper {
  text-align: center;
  margin: 20px auto;
}
.result-wrapper {
  padding: 20px;
  font-size: 14px;
}

.result-wrapper div {
  display: flex;
  margin: 10px auto;
}

.result-wrapper a {
  display: inline-block;
  max-width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

</style>
